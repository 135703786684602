<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col sm="12" md="12" lg="12" xl="12">
				<filters @selectedFilter="applyFiltersEvent($event)"></filters>
			</b-col>
		</b-row>
		<b-row class="match-height">
			<b-col lg="12">
				<b-row class="match-height">
					<b-col sm="12" md="12" lg="12" xl="12">
						<b-card no-body class="mb-0">
							<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">

								<b-card-header>
									<b-card-title><span v-if="siteObject">Site: {{ siteObject.siteURL }}</span></b-card-title>
								</b-card-header>

								<b-table ref="refUserListTable" class="position-relative p-1" :items="tableSiteListArray" responsive :fields="tableColumnsArray" primary-key="id" show-empty empty-text="No records found">

									<template #cell(type)="data">
										<font-awesome-icon :icon="data.item.type.avatar" :title="data.item.type.title" class="fa-2x" />
									</template>

									<template #cell(mobile_conversion)="data">
										<span>{{ data.item.mobile_conversion }}</span>
									</template>

									<template #cell(desktop_conversion)="data">
										<span>{{ data.item.desktop_conversion }}</span>
									</template>

								</b-table>

							</b-overlay>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import dateTypeListOptions from "@/libs/dateTypeListOptions";
	import captureIcons from "@/libs/capture-Icons";
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable
	} from "bootstrap-vue";

	import Filters from "./Filter.vue";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BButton, BTable,
			Filters,
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				statisticsFilterData: {},
				tableColumnsArray: [
					{ key: 'title', label: 'Title' },
					{ key: 'type', label: 'Type' },
					{ key: 'mobile_conversion', label: 'Mobile Conversion' },
					{ key: 'desktop_conversion', label: 'Desktop Conversion' },
				],
				tableSiteListArray: [],
				selectedFilters: {},
				siteObject: {},
				
			};
		},
		watch: {
		},
		methods: {
			applyFiltersEvent(filterObject) {

				this.tableSiteListArray = [];
				this.selectedFilters = filterObject; /* ADD FILERTS TO OBJECT */
				this.siteObject = filterObject.site;

				let dateType_custom = filterObject.date_type_custom_filter ? JSON.parse(filterObject.date_type_custom_filter) : {};
				this.fetchSiteConversions({
					date_type: filterObject.date_type,
					custom_start_date: dateType_custom.start ?? '',
					custom_end_date: dateType_custom.end ?? '',
				});
				/* SET ALL SITES TO SITE ARRAY AND LOAD FIRST 10 RECORDS : END */

			},
			fetchSiteConversions(selectedFilter) {

				this.isFetchingRecordsFromServer = true;
				this.$reportAPI.post('fetch-site-mobile-vs-desktop-conversions', {
					'auth_id': this.$root.authUserObject.id ?? '',
					'site_id': this.siteObject.siteId ?? '',
					'date_type': selectedFilter.date_type ?? '',
					'custom_start_date': selectedFilter.custom_start_date ?? '',
					'custom_end_date': selectedFilter.custom_end_date ?? '',
				}).then((response) => {
					response = response.data;
					if (response.status) {
						for (const settings of response.site_settings) {
							this.tableSiteListArray.push({
								title: settings.title,
								type: this.styleTypeIcons[settings.styleType],
								mobile_conversion: settings.mobile,
								desktop_conversion: settings.desktop,
							});
						}
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
					this.isFetchingRecordsFromServer = false;
				});
			}
		},
		created() { },
		setup() {

			const {
				dateTypeOptions,
			} = dateTypeListOptions()

			const {
				styleTypeIcons
			} = captureIcons();
			
			return {
				dateTypeOptions,
				styleTypeIcons
			}
		},
	};
</script>